import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import SEO from "../components/seo"

const StyleSheet = styled.div`
  margin-top: 20px;
  color: #666;
  & > p {
    line-height: 1.2;
    margin-bottom: 10px;
  }
`

const AboutPage = ({ data }) => {
  return (
    <div>
      <Layout active={'about'}>
        <SEO title="About us" />
        <StyleSheet>
          <ReactMarkdown children={data.file.childMarkdownRemark.rawMarkdownBody} />
        </StyleSheet>
      </Layout>
    </div>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    file(name: {eq: "about"}) {
      childMarkdownRemark {
        rawMarkdownBody
        frontmatter {
          title
        }
      }
    }
  }
`
